import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Box, Tabs, Tab } from "@mui/material"
import { useRecoilValue } from 'recoil'

import { StatusBar } from '../UI/StatusBar/StatusBar'
import { LoggedInAs } from "../UI/LoggedInAs/LoggedInAs"
import { DisableTrafficCapturingButton } from
  './DisableTrafficCapturingButton/DisableTrafficCapturingButton'
import { ApiCallsView } from "./ApiCallsView/ApiCallsView"
import { ServiceMapView } from "./ServiceMapView/ServiceMapView"

import { useAuth } from "../UI/Auth/Auth"
import useStreamingList from './useStreamingList'
import useWebSocket, { THROTTLE_WS_STATE_CHANGE_MS } from './useWebSocket'

import { resetHeapCache } from '../../helpers/cacher/cacher'

import {
  appAuthEnabled,
  appStopTrafficCapturingDisabled
} from '../../types/global'

import queryAtom from "../../recoil/query"
import packetCapturingStoppedAtom from '../../recoil/packetCapturingStopped'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from
  "../../recoil/trafficPlayback/atom"
import fullscreenViewAtom from "../../recoil/fullscreenView/atom"

import TrafficViewerStyles from "./TrafficViewer.module.sass"

import { StreamingPauseIcon } from '../UI/Icons/StreamingPauseIcon'
import { StreamingPlayIcon } from '../UI/Icons/StreamingPlayIcon'

import queryIsReadyAtom from '../../recoil/queryIsReady'

export const TrafficViewer: React.FC = () => {
  const query = useRecoilValue(queryAtom)
  const queryIsReady = useRecoilValue(queryIsReadyAtom)
  const fullscreenView = useRecoilValue(fullscreenViewAtom)
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  const [currentTab, setCurrentTab] = useState(0)
  const [playbackClicked, setPlaybackClicked] = useState(false)

  const streamingListRef = useRef(null)

  const { shouldAuthenticate } = useAuth()
  const { setToBottom, connectionTitle, connectionIndicator } = useStreamingList()
  const { open, send, close, toggle, readyState } = useWebSocket()

  useEffect(() => {
    if (readyState !== WebSocket.OPEN || !queryIsReady) {
      return
    }

    setToBottom(true)
    send(query)
  }, [readyState, queryIsReady, query])

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK || shouldAuthenticate) {
      close()
      return
    }

    if (
      !appStopTrafficCapturingDisabled() &&
      (capturingStopped === null || capturingStopped)
    ) {
      close()
      return
    }

    open()

    return () => resetHeapCache()
  }, [trafficPlayback, shouldAuthenticate, capturingStopped])

  const toggleConnection = useCallback(() => {
    if (
      !appStopTrafficCapturingDisabled() &&
      (capturingStopped === null || capturingStopped)
    ) {
      return
    }

    toggle()
  }, [capturingStopped])

  useEffect(() => {
    if (playbackClicked) {
      setTimeout(() => setPlaybackClicked(false), THROTTLE_WS_STATE_CHANGE_MS)
    }
  }, [playbackClicked])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const composeTabClassNames = useCallback((index: number) => {
    let selectedTabClass = ''
    if (currentTab === index) {
      selectedTabClass = TrafficViewerStyles.TrafficPageNavTabSelected
    }

    return `${TrafficViewerStyles.TrafficPageNavTab} ${selectedTabClass}`
  }, [currentTab])

  return (
    <div
      className={TrafficViewerStyles.TrafficPage}
      style={{ height: fullscreenView ? '100%' : 'calc(100% - 70px)' }}
    >
      {!fullscreenView && (
        <Box>
          <StatusBar />
          <div className={TrafficViewerStyles.TrafficPageHeader}>
            <div className={TrafficViewerStyles.TrafficPageStreamStatus}>
              <Box
                className={TrafficViewerStyles.playPauseIcon}
                boxSizing='border-box'
                onClick={() => {
                  if (playbackClicked) {
                    return
                  }

                  setPlaybackClicked(true)
                  toggleConnection()
                }}
                style={{
                  transition: 'opacity 0.2s',
                  opacity: playbackClicked ? 0.5 : 1,
                  pointerEvents: playbackClicked ? 'none' : 'all',
                }}
              >
                {!playbackClicked && readyState === WebSocket.OPEN ?
                  <StreamingPauseIcon /> :
                  <StreamingPlayIcon />
                }
              </Box>
              <div
                className={TrafficViewerStyles.connectionText}
                style={{
                  opacity: playbackClicked ? 0.8 : 1,
                }}
              >
                {playbackClicked ? 'changing...' : connectionTitle}
                {!playbackClicked && connectionIndicator}
              </div>
            </div>
            <Box boxSizing='border-box' position='absolute' left='250px'>
              <DisableTrafficCapturingButton mode='link' size='small' />
            </Box>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              indicatorColor='secondary'
              aria-label='Tabs navigation'
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab
                label='API Calls'
                className={composeTabClassNames(0)}
                hidden={currentTab !== 0}
              />
              <Tab
                label='Service Map'
                className={composeTabClassNames(1)}
                hidden={currentTab !== 1}
              />
            </Tabs>
            {appAuthEnabled() && (
              <Box
                position='absolute'
                top='50%'
                right='24px'
                sx={{ transform: 'translateY(-50%)' }}
              >
                <LoggedInAs />
              </Box>
            )}
          </div>
        </Box>
      )}
      {currentTab === 0 && <ApiCallsView scrollableRef={streamingListRef} />}
      {currentTab === 1 && <ServiceMapView />}
    </div>
  )
};
