import React from 'react'

import byteSize from 'byte-size'

import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'

import { Tooltip, IconButton } from '@mui/material'
import { BodyCell } from '../../UI/Table/Table'
import { HeadCell } from '../../UI/Table/TableHead'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import createRecordingDialogOpenAtom from '../../../recoil/createRecordingDialogOpen/atom'
import queryAtom from '../../../recoil/query/atom'
import queryBuildAtom from '../../../recoil/queryBuild/atom'
import useWebSocket from '../../TrafficViewer/useWebSocket'

export interface RawRecording {
  name: string
  size: number
  node: string,
  worker: string
}

export interface Recording {
  name: string
  size: number
  nodes: Node[]
}

export interface Node {
  name: string
  worker: string
}

export const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'size',
    label: 'Size'
  },
  {
    id: 'view',
    align: 'center',
    disableSorting: true,
    label: 'View'
  },
]

export const getBodyCells = (): BodyCell[] => {
  const setQuery = useSetRecoilState(queryAtom)
  const setQueryBuild = useSetRecoilState(queryBuildAtom)
  const setDialogOpen = useSetRecoilState(createRecordingDialogOpenAtom)

  const navigate = useNavigate()
  const location = useLocation()
  const { reopen } = useWebSocket()

  const handleViewRecordClick = (event, row: Recording) => {
    event.stopPropagation()

    const recordingQuery = `record("${row.name}")`

    setQuery(recordingQuery)
    setQueryBuild(recordingQuery)

    navigate({
      pathname: location.pathname,
      search: `q=${encodeURIComponent(recordingQuery)}`
    })

    reopen()

    setDialogOpen(false)
  }

  return [
    {
      headCellId: 'checkbox',
      align: 'center',
      render: 'checkbox'
    },
    {
      headCellId: 'name',
      align: 'left',
      render: (recording: Recording) => {
        return recording.name
      }
    },
    {
      headCellId: 'size',
      align: 'left',
      render: (recording: Recording) => {
        return `${byteSize(recording.size)}`
      }
    },
    {
      headCellId: 'view',
      align: 'center',
      render: (recording: Recording) => {
        return (
          <Tooltip title='View traffic in dashboard'>
            <IconButton
              onClick={(event) => handleViewRecordClick(event, recording)}
            >
              <ViewTimelineIcon />
            </IconButton>
          </Tooltip>
        )
      }
    },
  ]
}
