import { useIdleTimer } from 'react-idle-timer'
import { toast } from 'react-toastify'
import { HubWsUrl } from '../../consts'
import { appAuthEnabled, appOidcAuthTypeEnabled } from '../../types/global'
import { getRefreshToken, getSessionToken } from '@descope/react-sdk'
import { useRecoilState } from 'recoil'
import websocketReadyStateAtom from '../../recoil/websocketReadyState'
import { useWebSocketEventHandlers } from './useWebSocketEventHandlers'
import useThrottling from '../../hooks/useThrottling'
import { useInterval } from '../../helpers/interval'

export const THROTTLE_WS_STATE_CHANGE_MS = 1000

export let wsConn: WebSocket;

const useWebSocket = () => {
  const [readyState, setReadyState] = useRecoilState(websocketReadyStateAtom)

  const { onMessage, onError } = useWebSocketEventHandlers()

  useInterval(() => {
    if (!wsConn) {
      return
    }

    setReadyState(wsConn.readyState)
  }, 500, true)

  const open = () => {
    const url = new URL(HubWsUrl)

    if (appAuthEnabled() && appOidcAuthTypeEnabled()) {
      url.searchParams.set('session-token', getSessionToken());
      url.searchParams.set('refresh-token', getRefreshToken());
    }

    try {
      wsConn = new WebSocket(url)
      wsConn.onmessage = onMessage
      wsConn.onerror = onError
    } catch (e) {
      console.error(e)
    }
  }

  const send = (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
    if (wsConn && wsConn.readyState === WebSocket.OPEN) {
      wsConn.send(data)
    }
  }

  const close = () => {
    if (wsConn && wsConn.readyState === WebSocket.OPEN) {
      wsConn.close(1000)
    }
  }

  const reopen = useThrottling(() => {
    close()
    setTimeout(() => {
      open()
    }, 500)
  }, THROTTLE_WS_STATE_CHANGE_MS)

  const toggle = useThrottling(() => {
    if (!wsConn) {
      return
    }

    if (wsConn.readyState === WebSocket.OPEN) {
      close()
    } else {
      open()
    }
  }, THROTTLE_WS_STATE_CHANGE_MS)

  useIdleTimer({
    timeout: 5 * 60 * 1000,
    onIdle: () => {
      if (wsConn && wsConn.readyState === WebSocket.OPEN) {
        close()
        toast.warning("Connection is closed because of inactivity!", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    },
  })

  return {
    open,
    send,
    close,
    reopen,
    toggle,
    readyState,
    setReadyState,
  }
}

export default useWebSocket
